/* ========================================================================
     Component: table-extras
 ========================================================================== */


// Add support to components inside tables

.table {
    > thead > tr > th {
        padding-top: 14px;
        padding-bottom: 14px;
        color: #888;
    }

    > tbody > tr > td {
        vertical-align: middle;
        > .media {
            img {
                width: 36px;
                height: 36px;
                margin: 0 auto;
            }
        }
    }

    .checkbox {
        margin: 0 auto;
        width: 20px;
    }

    .progress {
        margin-bottom: 0
    }

    .radial-bar {
        margin-bottom: 0;
        margin: 0 auto;
    }

}

.jqstooltip {
    box-sizing: content-box;
}

// Extended table demos

#table-ext-1 {
    th {
        &:nth-child(1) {
            width: 3%;
        }

        &:nth-child(2) {
            width: 5%;
        }

        &:nth-child(7) {
            width: 5%;
        }

        &:nth-child(9) {
            width: 5%;
        }

    }

}

#table-ext-2 {
    th {
        &:nth-child(1) {
            width: 5%;
        }

        &:nth-child(3) {
            width: 10%;
        }

    }

}
