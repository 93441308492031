.unReadStatus {
  background-color: $gray-light;
}

.outcome-color {
  color: $color-sunset !important;
}
.w-26 {
  width: 26%;
}
.mw-25 {
  max-width: 25%;
}

.mw-50 {
  max-width: 50%;
}

.mw-75 {
  max-width: 75%;
}

.mw-90 {
  max-width: 90%;
}

.mb-negative3 {
  margin-bottom: -3px;
}

.pointer-none {
  pointer-events: none;
}

.text-truncate-2line {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.react-select-box-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: inline-block;
  width: 100%;
}

.Toastify__toast-container--top-right {
  top: 4em !important;
}

/* Added this to reduced the width of the dropdown in tinymce editor */
.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  width: auto !important;
}

.tox-toolbar {
  background-color: $color-silver !important;
}

.attachment-bg {
  background-color: $color-silver;
}

// toggle-switch 10.3

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  top: 5px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-grey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 2px;
  background-color: $secondary;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $brand-success;
}

input:focus + .slider {
  box-shadow: 0 0 1px $info;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.on {
  display: none;
  left: 28%;
}

.on,
.off {
  color: $secondary;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 10px;
}

.off {
  left: 70%;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

// settings start

.tbgwidth {
  max-width: 100%;
  overflow-x: auto;
}

.days-wrapper .day .hour {
  width: 64px;
  height: 35px;
  margin: 1px 1px 0 0;
  background-color: $color-wolf;
  float: left;
  cursor: pointer;
  border: 1px solid white;
}

.active-block {
  width: 64px;
  height: 35px;
  margin: 1px 1px 0 0;
  background-color: $brand-success;
  float: left;
  cursor: pointer;
  border: 1px solid white;
}

.schedule {
  width: 100%;
}

.schedule .hour-header {
  width: 3120px;
  padding-left: 0;
  padding-bottom: 10px;
  overflow: hidden;
}

.schedule .hour-header .hour-header-item {
  float: left;
  width: 65px;
  text-align: center;
}

.schedule .hour-header .hour-header-item h5 {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: normal;
}

.timeshedule {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 3120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.days-wrapper {
  width: 3120px;
  float: left;
  position: relative;
  padding-left: 0;
}

.schedule .header {
  float: left;
  position: absolute;
  top: 0;
  left: 0px;
  background: $secondary;
  z-index: 1;
  padding-top: 24px;
}

.schedule .header h3 {
  margin: 0;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: normal;
}

.days-wrapper .day {
  float: left;
  width: 3120px;
  position: relative;
}

.schedule .header .header-item {
  position: relative;
}

.borderless {
  border: none;
}

//settings end

.dropdown-list .dropdown-item {
  padding: 0.1875rem 1.25rem;
}

p.list-group-item-text {
  font-size: 13px !important;
}

.change-size {
  font-size: 0.845rem;
}

.right-3 {
  right: 3em;
}

//List Pages - Thin circle
.thin-circle {
  color: $color-white;
  -webkit-text-stroke: $inverse;
  -webkit-text-stroke-width: thin;
}

//View Prospect - Member Activity
.nav-tabs-bg {
  .nav-link.active {
    background-color: $color-white !important;
  }
  .nav-link {
    background-color: $card-cap-bg !important;
  }
}

.tox-tinymce--toolbar-bottom {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 0.25rem !important;
}

.tox-collection__group {
  max-height: 300px !important;
}

.mt-negative1 {
  margin-top: -1px;
}

// Toggle-btn color
.custom-control-label {
  cursor: pointer;
}
.custom-control-input ~ .custom-control-label {
  &::before {
    background-color: $color-white !important;
    border-color: $gray !important;
    top: 0.1rem !important;
    height: 1.15rem !important;
    width: 2rem !important;
    border-radius: 0.6rem !important;
  }
}

.custom-control-input ~ .custom-control-label {
  &::after {
    background-color: $primary !important;
    border-radius: 50% !important;
    left: calc(-2.25rem + 4px) !important;
  }
}

.custom-control-input:checked ~ .custom-control-label {
  &::after {
    background-color: $color-white !important;
  }
}

.custom-control-input:checked ~ .custom-control-label {
  &::before {
    background-color: $primary !important;
    border-color: $color-white;
  }
}

//Header - Notifications
.link-text {
  color: $color-dark-midnight-express;
}
.link-text:hover {
  color: $primary;
}

//Member Activity - CallOut Design
.common-wrap {
  position: relative;
}
.number-section {
  position: absolute;
  left: -25px;
  top: 31px;
}
.common-wrap .icon {
  position: absolute;
  top: 24px;
  left: 10px;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 1px solid $color-grey !important;
  background: $color-white !important;
  line-height: 30px;
  text-align: center;
  color: $color-blue !important;
}
.content-section {
  background: $color-white;
  position: relative;
  text-align: left;
  border: 1px solid $color-mako;
}
.content-section:before {
  content: ' ';
  display: inline-block;
  position: absolute;
  top: 23px;
  border-top: 15px solid transparent;
  border-right: 0 solid $color-white;
  border-bottom: 15px solid transparent;
  border-left: 15px solid $color-white;
  right: auto;
  left: -14px;
  border-right-width: 15px;
  border-left-width: 0;
  z-index: 1;
  transition: all 0.3s ease;
}

.content-section:after {
  content: ' ';
  display: inline-block;
  position: absolute;
  top: 23px;
  border-top: 15px solid transparent;
  border-right: 0 solid $color-wolf;
  border-bottom: 15px solid transparent;
  border-left: 15px solid $color-wolf;
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.member-list {
  position: relative;
}

.member-list:before {
  content: '';
  position: absolute;
  top: 0;
  left: 40px;
  height: 150%;
  width: 3px;
  background: $color-silver;
}
.ml-activity:before {
  left: 63px !important;
}
.common-wrap:hover .icon {
  color: $color-white !important;
  background: $gradient-baltic-sea-start !important;
  border: 1px solid $gradient-baltic-sea-start !important;
}
.common-wrap:hover .content-section:before {
  border-right: 15px solid $color-silver !important;
  border-left: 0 solid $color-silver !important;
}
.common-wrap:hover .content-section {
  background: $color-silver;
  border: 1px solid $color-silver;
}

.wd-append-btn {
  width: 43px;
}

.circle-icon {
  width: 2.3rem;
  height: 2.3rem;
  &:hover {
    background-color: #21bfe6;
    border-color: #21bfe6 !important;
    color: $color-white;
  }
}

.circle-icon-action {
  width: 2.3rem;
  height: 2.3rem;
  color: $color-dark-midnight-express;
  &:hover {
    background-color: #21bfe6;
    border-color: #21bfe6 !important;
    color: $color-white;
  }
}

.engagementscore-chart .apexcharts-canvas {
  margin: 0 auto;
}

.invalid-date {
  margin-top: 34px;
}

.dropdown-width {
  left: 17px;
  min-width: -webkit-fill-available;
}

// Todo Action Icon Alignment
.personalize-email-todo {
  padding-top: 1px;
  padding-left: 3px;
  font-size: 13px;
}

.social-icon-todo {
  padding-left: 2px;
  padding-top: 1px;
  font-size: 13px;
}

.linkedin-icon-todo {
  padding-top: 0.5px;
  padding-left: 1px;
  font-size: 13px;
}

// TODO This style can be used later
/* .popover .arrow {
  left: -2px !important;
} */

.reminder-popover .popover-inner {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.32) !important;
}

// favorite cadences
.overstats {
  .col {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    &:last-child {
      border-right: 0;
    }
  }
  i {
    display: none;
  }
  p {
    display: block !important;
    margin-top: 3px;
  }
  //show the stats columns outcomes names while hovering the row in accounts list page
  small {
    display: inline !important;
  }
}
.favorite-cadence {
  .favorite-cadence-item {
    visibility: visible !important;
  }
}

// favorite templates
.favorite-template {
  .favorite-template-item {
    visibility: visible !important;
  }
}

// active cadences user dropdown
.active-cadence .input-group .form-control {
  height: 50% !important;
  padding: 5px;
  font-size: 0.75rem;
}

.active-cadence .dropdown-item {
  font-size: 0.75rem !important;
}

.active-cadence .input-group .btn {
  padding: 0px 5px;
}
.active-cadence .btn-group .disabled {
  padding: 0px 5px !important;
  .active-cadence .btn-group .fa {
    font-size: 0.875em;
  }
}

.active-cadence .input-group .fas {
  font-size: 0.875em;
}

.email_templates_popover .arrow {
  left: 60px !important;
}

//Dashboard - pending widgets
.dashboard-pending-widgets .slick-track {
  float: left !important;
}

//Dashboard - Email Metrics
@media (min-width: 992px) and (max-width: 1440px) {
  .show-metrics {
    justify-content: flex-start !important;
  }
  .show-metrics > .fa-2x {
    margin: 0rem 0.25rem 0rem 0.5rem !important;
  }
}

@media (min-width: 1441px) {
  .show-metrics > .fa-2x {
    margin: 0rem 0.5rem 0rem 1rem !important;
  }
}

// Below classes are used for skeleton loading
/* basic styles */
.skeleton {
  background: #ddd;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
}
.skeleton.text {
  width: 100%;
  height: 12px;
}
.skeleton.title {
  width: 50%;
  height: 20px;
  margin-bottom: 15px;
}
.skeleton.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.skeleton.thumbnail {
  width: 100px;
  height: 100px;
}
.skeleton-wrapper {
  margin: 20px auto;
  padding: 10px 15px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

/* skeleton profile */
.skeleton-profile {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  align-items: center;
}

/* themes */
.skeleton-wrapper.light {
  background: #f2f2f2;
}
.skeleton-wrapper.dark {
  background: #444;
}
.skeleton-wrapper.dark .skeleton {
  background: #777;
}

/* animation effects */
.shimmer-wrapper {
  position: absolute;
  /* parent must be relative position & overflow hidden */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2.5s infinite;
}
.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.2);
}
.dark .shimmer {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}

// Meeting
.dhtmlx-react-calendar {
  // Added for not able to track the booked event to end of the time in Calendar.
  .dhx_cal_container {
    overflow: visible;
    margin-bottom: 11px;
    &:after {
      content: '';
      position: absolute;
      height: 15px;
      bottom: -11px;
      left: 0;
      right: 0;
    }
    &.dhx_scheduler_day {
      // Hide small title in Meetings booking days calendar view
      .dhx_cal_header {
        display: none;
      }
    }
  }
}
.working_hour div {
  background-color: #2bcd69 !important;
  color: white !important;
}
.tooltip-header {
  background-color: #2bcd69 !important;
  padding: 10px !important;
}
.non-working-hour div {
  background-color: #064468c9 !important;
}
.non-working-hour-header {
  background-color: #064468c9 !important;
  padding: 10px !important;
}

.add-meeting-btn {
  margin-right: 5px !important;
  width: 150px !important;
}

.layout-fixed {
  .dhtmlXTooltip.tooltip {
    padding: 1px;
    z-index: 1000;
  }
}
.dhx_event_resize.dhx_footer {
  margin-top: -5px !important;
}
//Prospect Re-Schedule
.prospect-meeting-reschedule .container-lg {
  max-width: 800px;
}
.prospect-meeting-reschedule .container {
  max-width: 900px;
}
.react-custom-calender .react-calendar__navigation {
  height: 18px;
  margin: 10px 0 10px;
}
.react-custom-calender .react-calendar__navigation__label {
  order: 1;
  text-align: left;
  font-weight: 500;
  padding-left: 0;
  color: #6b7695;
}
.react-custom-calender .react-calendar__navigation button:enabled:hover,
.react-custom-calender .react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}
.react-custom-calender .react-calendar__navigation__arrow {
  order: 2;
  font-size: 0;
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  color: #6b7695;
}
.react-custom-calender .react-calendar__navigation__prev2-button,
.react-custom-calender .react-calendar__navigation__next2-button {
  display: none;
}
.react-custom-calender .react-calendar__navigation__arrow:before {
  font-size: 16px;
}
.react-custom-calender .react-calendar__navigation__prev-button:before {
  content: '\f104';
}
.react-custom-calender .react-calendar__navigation__next-button:before {
  content: '\f105';
}
.react-custom-calender .react-calendar__tile:disabled {
  background-color: #f9f9f9;
}
.react-custom-calender .react-calendar__month-view__days button {
  margin: 2px 2% !important;
  border-radius: 30px;
  flex-basis: 10.2857% !important;
  max-width: 10.2857% !important;
}
.react-custom-calender .react-calendar__month-view__weekdays__weekday {
  color: #6b7695;
}
.react-custom-calender .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  cursor: default;
}
.react-custom-calender .react-calendar__tile--active {
  background-color: #006edc;
}
.react-custom-calender .react-calendar__tile:enabled:hover,
.react-custom-calender .react-calendar__tile:enabled:focus {
  background-color: #dbedff;
  color: #006edc;
  font-weight: bold;
}
.react-custom-calender .react-calendar__month-view__days__day--weekend {
  color: #ffa7a7;
}
.react-custom-calender
  .react-calendar__month-view__days__day--neighboringMonth {
  color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
}
.animate-fast {
  -webkit-animation-duration: 0.8s !important;
  animation-duration: 0.8s !important;
}

// Signup Process
.wrapper .onboard-section-container {
  margin-bottom: 0 !important;
}
.mainonboard-wrapper {
  background-color: #f9fafb;
}
.mainonboard-wrapper:before {
  content: '';
  // TODO This will be uncommented once xdr build issue fixed
  // background: url(../../../../../shared-components/src/lib/images/line-pattern-dash-left.svg) no-repeat;
  background-position: left top;
  min-width: 529px;
  max-width: 40%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.mainonboard-wrapper:after {
  content: '';
  // TODO This will be uncommented once xdr build issue fixed
  // background: url(../../../../../shared-components/src/lib/images/line-pattern-dash-right.svg) no-repeat;
  background-position: left top;
  min-width: 529px;
  max-width: 40%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.onboard-box {
  color: #656d93;
  border-radius: 6px;
  z-index: 1;
  .onboard-boxin {
    padding: 38px;
  }
  .form-control {
    height: 45px;
    border-radius: 8px;
  }
  .btn-lg {
    height: 45px;
    font-size: 16px;
    border-radius: 8px;
  }
}
.onboard-footer-social {
  font-size: 24px;
  color: #98a2b3;
}

//Loading Screen
.loading-screen-wrapper {
  z-index: 99999;
}
#loading-screen-carousal {
  border-radius: 20px;
  overflow: hidden;
}
.loading-screen-slider-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* animation: zoom ease forwards; */
  animation-duration: 4s;
  border-radius: 20px;
}
.loading-screen-slider-col {
  .carousel {
    border-radius: 20px;
  }
  .carousel-indicators {
    margin: 0;
    bottom: auto;
    top: 25px;
    right: 30px;
    left: 30px;
    pointer-events: none;
    li {
      width: 100%;
      height: 4px;
      background: rgba(255, 255, 255, 0.3);
      border: 0;
      border-radius: 10px;
      opacity: 1;
      &[data-indicate='done'] {
        span {
          animation: indicator forwards;
          animation-duration: 4s;
        }
      }
      span {
        background: #fff;
        width: 0;
        height: 4px;
        display: block;
        border-radius: 10px;
      }
    }
    .active {
      background: rgba(255, 255, 255, 0.3);
    }
  }
  .carousel-inner {
    height: 100%;
    .carousel-item {
      .loading-screen-slider-bg {
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
      }
      .carousel-img-wrapper {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 30px;
      }
      .carousel-item-info {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .performer-badge-list {
    left: 0;
    right: 0;
    bottom: 40px;
  }
}

.onboard-step-col {
  width: 260px;
  color: #acb6c5;
  &.done,
  &.active {
    color: #354c71;
  }
  &:before {
    content: '';
    background: #e5e8ed;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 20.5px;
    left: 50%;
  }
  &.done:before {
    background: #155eef;
  }
  &:last-child:before {
    display: none;
  }
  span {
    width: 41px;
    height: 41px;
    background: #f9fafb;
    color: #e4e7ec;
    font-size: 9px;
  }
  &.done span {
    background: #94ce69;
    color: #fff;
  }
  &.active span {
    background: #dce8ff;
    color: #155eef;
  }
  span i {
    margin-right: -1px;
  }
  b {
    font-weight: normal;
  }
}
.loading-screen-content-col {
  h2 {
    font-size: 40px;
    margin: 0 0 35px;
    color: #80a1e0;
  }
  p {
    font-size: 18px;
  }
  .onboard-copy-right {
    bottom: 40px;
    left: 50px;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.08);
  }
}
@keyframes indicator {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

/* Responsive */
@media (max-height: 770px) {
  .loading-screen-slider-col {
    .carousel-item-info {
      margin-top: 15px !important;
    }
    .carousel-item-info h2 {
      font-size: 24px;
      margin-bottom: 6px;
    }
    .performer-badge-list {
      bottom: 10px !important;
      svg {
        width: 60px;
        height: 60px;
      }
    }
  }
  .onboard-step-sec {
    margin: 27px 0 20px !important;
  }
  .loading-screen-content-col {
    h2 {
      font-size: 32px;
      margin-bottom: 14px;
    }
    p {
      font-size: 16px;
    }
  }
}
@media (max-width: 1320px) {
  .mainonboard-wrapper:before,
  .mainonboard-wrapper:after {
    width: 45%;
  }
}

// Below styles are used to create styled list items for this GIT ID #11735
.koncert-styled-list {
  height: 60px;
  display: flex;
  align-items: center;
  background: #edf2f7;
  border-radius: 10px;
  border: none;
  margin: 5px 0;
}

.enabled.koncert-styled-list:hover {
  background: #319795;
  color: white;
  cursor: pointer;
}

// Webrtc styles ---> Start
.connect-audio-popup {
  background: none;
  max-width: 600px;
  font-size: 12px;
}
.connect-audio-popup .modal-content {
  border-radius: 8px;
}
.connect-audio-tt {
  font-size: 0.875rem;
}
.connect-audio-popup .nav-pills {
  padding: 2px;
  border-radius: 8px;
}
.connect-audio-popup .nav-pills .nav-link {
  color: #444;
  border: 0;
  border-radius: 7px;
  box-shadow: none;
}
.connect-audio-popup .nav-pills .nav-link.active {
  background-color: #fff;
  color: #21bfe6;
}
.connect-audio-popup .nav-pills .nav-link:focus {
  box-shadow: none !important;
}
.close-connect-audio {
  font-size: 1.25rem;
  margin-top: -10px;
  margin-right: -4px;
}
// Webrtc styles ---> End

.volumen-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.led {
  width: 20px;
  height: 25px;
  box-shadow: -2px -2px 4px 0px #a7a7a73d inset, 2px 2px 4px 0px #0a0a0e5e inset;
  margin: 0 0 0 2px;
}
.audio-control {
  cursor: pointer;
  color: #fff;
  box-shadow: -2px -2px 4px 0px #a7a7a73d, 2px 2px 4px 0px #0a0a0e5e;
}

.mic-selc .ch-select-label {
  display: none !important;
}
.mic-selc {
  margin-bottom: 0px !important;
}

.mic-selc .ch-select,
.spk-selc .ch-select {
  height: 22px;
  font-size: 12px;
  padding: 0px 25px 0px 5px;
}
.mic-selc .ch-select-icon,
.spk-selc .ch-select-icon {
  height: 20px !important;
  width: 20px !important;
}

.spk-selc .ch-select-label {
  display: none !important;
}

.spk-selc {
  margin-bottom: 0px !important;
}
