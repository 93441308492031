.react-select-box-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: inline-block;
    width: 100%;
}
.Toastify__toast-container--top-right {
	top: 4em;
}