/* ========================================================================
     Component: half-float
 ========================================================================== */

//
// Float an absolute positioned element
// and align it with a half top and bottom offset
// relative to its parent
//
// .half-float
//  > .half-float-[top|bottom]
//
// ----------------------------------------------
$half-aligned-size : 80px; // Changed form 128px, need to check after live
$half-aligned-gutter : 5px;

.half-float {
    position: relative;
    margin-bottom: ($half-aligned-size*0.5) + $half-aligned-gutter;

    .half-float-bottom,
    .half-float-top {
        position: absolute;
        left: 50%;
        bottom: -($half-aligned-size*0.5);
        width: $half-aligned-size;
        height: $half-aligned-size;
        margin-left: -($half-aligned-size*0.5);
        z-index: 2;
    }

    .half-float-top {
        bottom: auto;
        top: -($half-aligned-size*0.5);
    }
}
