.extension-error {
  color: $color-poppy;
}

.extension-success {
  color: $color-grass;
}

.extension-warning {
  color: $color-orange;
}

.extension-away {
  color: $color-grey;
}

.info-popover {
  width: '250px';
  text-align: 'center';
}

.popover {
  text-align: 'center';
}

.info {
  width: '250px';
}

.status {
  width: '150px';
}
