@import 'icon-variables';

@font-face {
  font-family: '#{$icon-font-family}';

  src: url('#{$icon-font-path}/#{$icon-font-family}.ttf?5yxrkt')
      format('truetype'),
    url('#{$icon-font-path}/#{$icon-font-family}.woff?5yxrkt') format('woff'),
    url('#{$icon-font-path}/#{$icon-font-family}.icon?5yxrkt##{$icon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.svgicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icon-font-family}' !important;
  // speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.trucadence-icon {
  &:before {
    content: $trucadence-icon;
  }
}

.koncert-cadence-icon-flipped {
  &:before {
    content: $koncert-cadence-icon-flipped;
  }
}

.koncert-cadence-icon {
  &:before {
    content: $koncert-cadence-icon;
  }
}

.speed-30 .path1 {
  &:before {
    content: $speed-30-path1;
    color: rgb(255, 255, 255);
  }
}
.speed-30 .path2 {
  &:before {
    content: $speed-30-path2;
    margin-left: -1em;
    color: rgb(185, 190, 193);
  }
}
.speed-30 .path3 {
  &:before {
    content: $speed-30-path3;
    margin-left: -1em;
    color: rgb(232, 39, 48);
  }
}
.speed-30 .path4 {
  &:before {
    content: $speed-30-path4;
    margin-left: -1em;
    color: rgb(232, 39, 48);
  }
}
.speed-30 .path5 {
  &:before {
    content: $speed-30-path5;
    margin-left: -1em;
    color: rgb(232, 39, 48);
  }
}

.speed-100 .path1 {
  &:before {
    content: $speed-100-path1;
    color: rgb(255, 255, 255);
  }
}
.speed-100 .path2 {
  &:before {
    content: $speed-100-path2;
    margin-left: -1em;
    color: rgb(185, 190, 193);
  }
}
.speed-100 .path3 {
  &:before {
    content: $speed-100-path3;
    margin-left: -1em;
    color: rgb(232, 39, 48);
  }
}
.speed-100 .path4 {
  &:before {
    content: $speed-100-path4;
    margin-left: -1em;
    color: rgb(232, 39, 48);
  }
}
.speed-100 .path5 {
  &:before {
    content: $speed-100-path5;
    margin-left: -1em;
    color: rgb(232, 39, 48);
  }
}

.speed-800 .path1 {
  &:before {
    content: $speed-800-path1;
    color: rgb(255, 255, 255);
  }
}
.speed-800 .path2 {
  &:before {
    content: $speed-800-path2;
    margin-left: -1em;
    color: rgb(185, 190, 193);
  }
}
.speed-800 .path3 {
  &:before {
    content: $speed-800-path3;
    margin-left: -1em;
    color: rgb(232, 39, 48);
  }
}
.speed-800 .path4 {
  &:before {
    content: $speed-800-path4;
    margin-left: -1em;
    color: rgb(232, 39, 48);
  }
}
.speed-800 .path5 {
  &:before {
    content: $speed-800-path5;
    margin-left: -1em;
    color: rgb(232, 39, 48);
  }
}

.talktime {
  &:before {
    content: $talktime;
  }
}
.call-red-circle .path1 {
  &:before {
    content: $call-red-circle-path1;
    color: rgb(252, 54, 29);
  }
}
.call-red-circle .path2 {
  &:before {
    content: $call-red-circle-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.personal-dialor {
  &:before {
    content: $personal-dialor;
  }
}
.right-arrow {
  &:before {
    content: $right-arrow;
    color: #2a6c8e;
  }
}
.thumb {
  &:before {
    content: $thumb;
  }
}
.twoway_icon_white {
  &:before {
    content: $twoway_icon_white;
  }
}
.download {
  &:before {
    content: $download;
  }
}
.upload-one {
  &:before {
    content: $upload-one;
    color: #266dd3;
  }
}
.voice {
  &:before {
    content: $voice;
    color: #0997fb;
  }
}
.zig-zag {
  &:before {
    content: $zig-zag;
    color: #fd9bcb;
  }
}
.calling {
  &:before {
    content: $calling;
  }
}
.reports_icon_black {
  &:before {
    content: $reports_icon_black;
  }
}
.accounts_icon_black {
  &:before {
    content: $accounts_icon_black;
  }
}
.mycadence_icon_black {
  &:before {
    content: $mycadence_icon_black;
  }
}
.pending_calls_icon_black {
  &:before {
    content: $pending_calls_icon_black;
  }
}
.todo_icon_black {
  &:before {
    content: $todo_icon_black;
  }
}
.dashboard_icon_black {
  &:before {
    content: $dashboard_icon_black;
  }
}
.email_icon_black {
  &:before {
    content: $email_icon_black;
  }
}
.email-edit {
  &:before {
    content: $email-edit;
  }
}

.zipwhip .path1 {
  &:before {
    content: $zipwhip-path1;
    color: rgb(251, 113, 63);
  }
}
.zipwhip .path2 {
  &:before {
    content: $zipwhip-path2;
    margin-left: -1em;
    color: rgb(62, 68, 77);
  }
}
.zipwhip .path3 {
  &:before {
    content: $zipwhip-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
