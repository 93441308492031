@import '../bootstrap/functions';
@import '../bootstrap/variables';
@import '../bootstrap/mixins';
@import '../app/common/variables';
// svgicon
@import '../app/trucadence/icons/icon-style';

@import '../app/custom/custom-styles';

// trucadence
@import '../app/trucadence/trucadence.scss';

// Color system
$primary: $gradient-turquoise-dream-end;
$brand-primary: $gradient-turquoise-dream-end;

$gradient-brand-start: $gradient-turquoise-dream-start;
$gradient-brand-end: $gradient-turquoise-dream-end;

$colors: (
  'purple': $purple,
  'pink': $pink,
  'yellow': $yellow,
  'green': $green,
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'inverse': $inverse,
  'primary-light': $primary-light,
  'primary-dark': $primary-dark,
  'success-light': $success-light,
  'success-dark': $success-dark,
  'info-light': $info-light,
  'info-dark': $info-dark,
  'warning-light': $warning-light,
  'warning-dark': $warning-dark,
  'danger-light': $danger-light,
  'danger-dark': $danger-dark,
  'green-light': $green-light,
  'green-dark': $green-dark,
  'pink-light': $pink-light,
  'pink-dark': $pink-dark,
  'purple-light': $purple-light,
  'purple-dark': $purple-dark,
  'inverse-light': $inverse-light,
  'inverse-dark': $inverse-dark,
  'yellow-light': $yellow-light,
  'yellow-dark': $yellow-dark,
  'color-ocean': $color-ocean,
  'color-trueblue': $color-trueblue,
  'color-cyan': $color-cyan,
  'color-powder': $color-powder,
  'color-sky': $color-sky,
  'color-white': $color-white,
  'color-charcoal': $color-charcoal,
  'color-rock': $color-rock,
  'color-grey': $color-grey,
  'color-wolf': $color-wolf,
  'color-mako': $color-mako,
  'color-silver': $color-silver,
  'color-grass': $color-grass,
  'color-mint': $color-mint,
  'color-perry': $color-perry,
  'color-mist': $color-mist,
  'color-poppy': $color-poppy,
  'color-rouge': $color-rouge,
  'color-orange': $color-orange,
  'color-orange-light': $color-orange-light,
  'color-ocean-light': $color-ocean-light,
  'color-ocean-dark': $color-ocean-dark,
  'color-trueblue-light': $color-trueblue-light,
  'color-trueblue-dark': $color-trueblue-dark,
  'color-cyan-light': $color-cyan-light,
  'color-cyan-dark': $color-cyan-dark,
  'color-powder-light': $color-powder-light,
  'color-powder-dark': $color-powder-dark,
  'color-sky-light': $color-sky-light,
  'color-sky-dark': $color-sky-dark,
  'color-white-light': $color-white-light,
  'color-white-dark': $color-white-dark,
  'color-charcoal-light': $color-charcoal-light,
  'color-charcoal-dark': $color-charcoal-dark,
  'color-rock-light': $color-rock-light,
  'color-rock-dark': $color-rock-dark,
  'color-grey-light': $color-grey-light,
  'color-grey-dark': $color-grey-dark,
  'color-wolf-light': $color-wolf-light,
  'color-wolf-dark': $color-wolf-dark,
  'color-mako-light': $color-mako-light,
  'color-mako-dark': $color-mako-dark,
  'color-silver-light': $color-silver-light,
  'color-silver-dark': $color-silver-dark,
  'color-grass-light': $color-grass-light,
  'color-grass-dark': $color-grass-dark,
  'color-mint-light': $color-mint-light,
  'color-mint-dark': $color-mint-dark,
  'color-perry-light': $color-perry-light,
  'color-perry-dark': $color-perry-dark,
  'color-mist-light': $color-mist-light,
  'color-mist-dark': $color-mist-dark,
  'color-poppy-light': $color-poppy-light,
  'color-poppy-dark': $color-poppy-dark,
  'color-rouge-light': $color-rouge-light,
  'color-rouge-dark': $color-rouge-dark,
  'color-midnight-express': $color-midnight-express,
  'color-dark-midnight-express': $color-dark-midnight-express,
  'color-light-scarlet': $color-light-scarlet,
  'color-sunset': $color-sunset,
  'color-koncert-white': $color-koncert-white,
  'color-primary-shade': $color-primary-shade,
  'color-secondary-shade': $color-secondary-shade,
  'color-persian-blue': $color-persian-blue,
);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'color-midnight-express': $color-midnight-express,
  'color-dark-midnight-express': $color-dark-midnight-express,
  'color-persian-blue': $color-persian-blue,
  'color-light-scarlet': $color-light-scarlet,
  'color-sunset': $color-sunset,
  'color-koncert-white': $color-koncert-white,
  'color-primary-shade': $color-primary-shade,
  'color-secondary-shade': $color-secondary-shade,
);

$theme-gradients: 'blood-orange' $gradient-blood-orange-start
    $gradient-blood-orange-end,
  'blood-orange-light' $gradient-blood-orange-light-start
    $gradient-blood-orange-light-end,
  'turquoise-dream' $gradient-turquoise-dream-start
    $gradient-turquoise-dream-end,
  'purple-rain' $gradient-purple-rain-start $gradient-purple-rain-end,
  'baltic-sea' $gradient-baltic-sea-start $gradient-baltic-sea-end,
  'gothic-sky' $gradient-gothic-sky-start $gradient-gothic-sky-end,
  'brand' $gradient-brand-start $gradient-brand-end;

@import './theme-koncert.scss';

// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

@each $color, $value in $colors {
  @include bg-variant('.bg-#{$color}', $value);
}

@each $color, $start, $end in $theme-gradients {
  @include fa-gradient-koncert-variant('.fa-gradient-#{$color}', $start, $end);
}

@each $color, $start, $end in $theme-gradients {
  @include bg-gradient-koncert-variant('.bg-gradient-#{$color}', $start, $end);
}

@each $color, $start, $end in $theme-gradients {
  @include bg-gradient-koncert-variant(
    '.badge-gradient-#{$color}',
    $start,
    $end
  );
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn {
  &.btn-secondary {
    border-color: $btn-secondary-border;
  }
  &.btn-outline-secondary {
    border-color: $btn-secondary-border !important;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(
      theme-color-level($color, $alert-bg-level),
      theme-color-level($color, $alert-border-level),
      theme-color-level($color, $alert-color-level)
    );
  }
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

@each $color, $value in $theme-colors {
  @include list-group-item-variant(
    $color,
    theme-color-level($color, -9),
    theme-color-level($color, 6)
  );
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
  @include table-row-variant(
    $color,
    theme-color-level($color, $table-bg-level),
    theme-color-level($color, $table-border-level)
  );
}

@each $color, $value in $theme-colors {
  @include text-emphasis-variant('.text-#{$color}', $value);
}

.btn-secondary:hover { // btn-ouline:hover removed for overwriting the all buttons hove style
  color: $primary;
}

.table a:hover {
  color: $primary;
}

.modal-title {
  font-weight: bold;
}

// Dashboard widgets 5 in 1 row
@include media-breakpoint-up(xl) {
  .cadence-xl-auto {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

input[type='checkbox'].form-check-input,
input[type='radio'].form-check-input {
  margin-top: 0.2rem;
}

.text-sm input[type='checkbox'].form-check-input,
.text-sm input[type='radio'].form-check-input {
  margin-top: 0.13rem;
}

.border-grey {
  border: 1px solid $color-wolf !important;
}

.crm-csv-table .table-bordered th,
.table-bordered td {
  border: 1px solid $color-wolf !important;
}

/* CD Popup Phone Keypad START*/
.cd-phone-keyboard {
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  margin-top: -1px;
  background: #efefef;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}
.cd-phone-keyboard .close {
  width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 13px;
  right: 0;
  top: 0;
}
.cd-phone-keyboard li {
  display: flex;
  width: 41px;
  height: 41px;
  border-radius: 40px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 5px 11px;
  background: #fff;
  box-shadow: 0 0 5px 0 #c5c5c5;
  cursor: pointer;
}
.cd-phone-keyboard li:active {
  box-shadow: 0 0 5px 0 #4099ff;
  background: #4099ff;
  color: #fff;
}
.cd-phone-keyboard li b {
  display: block;
  margin: 0 0 -4px;
}
.cd-phone-keyboard li span {
  font-size: 10px;
}
.biggrey {
  font-size: 46px;
  display: block;
  margin-bottom: 25px;
  color: #464646;
  letter-spacing: -2px;
}
/* CD Popup Phone Keypad END*/
