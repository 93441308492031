.koncert-admin-header {
    z-index: 2050;
    background-color: $color-trueblue;
    @include gradient-x($color-trueblue, $color-ocean);
    
    .nav-link {
        color: $color-white;
        padding: 0.5rem .95rem;
    }
}
