.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
}

.content-height {
  width: 450px;
  max-height: 500px;
  overflow-y: auto
}

[data-initials]:before {
  background: grey;
  color: white;
  opacity: 1; 
  content: attr(data-initials); 
  display: inline-block; 
  font-weight: bold; 
  border-radius: 50%; 
  border:1px solid #dee2e6;  
  vertical-align: middle; 
  margin-right: 0.5em; 
  width: 50px; 
  height: 50px; 
  line-height: 50px; 
  text-align: center; 
  }

  @mixin avatar($size) {
    width: $size;
    height: $size;
    background-color: gray;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;

    .initials {
      position: relative;
      top: $size*0.20;
      font-size: $size*0.5;
      line-height: $size*0.5;
      color: #fff;
    }
}

.avatar-circle-sm   { @include avatar(30px); }
